<template>
  <section id="dashboard-analytics">
    <b-row class="mb-2">
      <b-col
        lg="4"
        class="d-flex flex-column justify-content-between"
      >
        <b-card-text>
          <span>Selecione uma campanha para filtrar o dashboard:</span>
        </b-card-text>
        <v-select
          v-model="campaignsFilterToken"
          placeholder="Todas as campanhas"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="campaignsOptions"
          :clearable="false"
          class="w-100"
          :reduce="val => val.value"
          :value="campaignsFilterToken"
        >
          <template #no-options>
            <span>Não encotramos nenhum resultado :(</span>
          </template>
        </v-select>

      </b-col>
      <b-col
        lg="4"
        class="d-flex flex-column justify-content-between"
      >
        <b-card-text>
          <span>Intervalo da datas:</span>
        </b-card-text>
        <flat-pickr
          id="rangeDate"
          v-model="periodDateFilter"
          class="form-control"
          placeholder="Selecione um período para filtrar..."
          :config="config"
          :value="periodDateFilter"
        />
        <small
          v-if="dateDiff > 90"
          class="text-danger"
        >O período precisa ser no máximo de 90 dias</small>
      </b-col>
      <b-col
        cols="4"
        class="d-flex flex-column justify-content-end"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="w-50"
          @click="refetchData"
        >
          Filtrar Dashboard
        </b-button>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        lg="6"
        md="12"
      >
        <b-overlay
          :show="isAPICallInProgress"
          rounded="sm"
          class="d-flex"
          style="flex: 1"
        >
          <users-data-congratulation :data="congratulations" />
        </b-overlay>
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <b-overlay
          :show="isAPICallInProgress"
          rounded="sm"
          class="d-flex"
          style="flex: 1"
        >
          <statistic-card-with-area-chart
            icon="UsersIcon"
            :statistic="usersRegisterData"
            statistic-title="Usuários Cadastrados"
          />
        </b-overlay>
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <b-overlay
          :show="isAPICallInProgress"
          rounded="sm"
          class="d-flex"
          style="flex: 1"
        >
          <statistic-card-with-area-chart
            icon="Share2Icon"
            color="warning"
            :statistic="sharingData"
            statistic-title="Compartilhando"
          />
        </b-overlay>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col cols="3">
        <b-overlay
          :show="isAPICallInProgress"
          rounded="sm"
        >
          <statistic-card-vertical
            icon="EyeIcon"
            :statistic="viewsData"
            statistic-title="Visualizações"
            color="info"
          />
        </b-overlay>
      </b-col>
      <b-col cols="3">
        <b-overlay
          :show="isAPICallInProgress"
          rounded="sm"
        >
          <statistic-card-vertical
            icon="MonitorIcon"
            :statistic="uniqueAccessData"
            statistic-title="Acessos Únicos"
            color="warning"
          />
        </b-overlay>
      </b-col>
      <b-col cols="3">
        <b-overlay
          :show="isAPICallInProgress"
          rounded="sm"
        >
          <statistic-card-vertical
            icon="UsersIcon"
            :statistic="userSharingData"
            statistic-title="Cadastros"
            color="danger"
          />
        </b-overlay>
      </b-col>
      <b-col cols="3">
        <b-overlay
          :show="isAPICallInProgress"
          rounded="sm"
        >
          <statistic-card-vertical
            icon="UserCheckIcon"
            :statistic="activeUsersData"
            statistic-title="Usuários Ativos"
            color="success"
          />
        </b-overlay>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col cols="4">
        <b-overlay
          :show="isAPICallInProgress"
          rounded="sm"
        >
          <user-data-earnings-chart
            icon="UsersIcon"
            :data="[registerPercentageData]"
            title="Cadastros"
            legend="Percentual de engajamento da sua campanha"
            color-of-graph="danger"
          />
        </b-overlay>
      </b-col>
      <b-col cols="4">
        <b-overlay
          :show="isAPICallInProgress"
          rounded="sm"
        >
          <user-data-earnings-chart
            icon="UserCheckIcon"
            :data="[activeUsersPercentageData]"
            title="Usuário ativos"
            legend="Percentual de usuários que validaram o e-mail e ativaram a conta"
            color-of-graph="success"
          />
        </b-overlay>
      </b-col>
      <b-col cols="4">
        <b-overlay
          :show="isAPICallInProgress"
          rounded="sm"
        >
          <user-data-earnings-chart
            icon="Share2Icon"
            :data="[sharingPercentageData]"
            title="Compartilhando"
            legend="Percentual de usuários que estão indicando"
            color-of-graph="primary"
          />
        </b-overlay>
      </b-col>
    </b-row>
  </section>
</template>

<script>

import { mapGetters } from 'vuex'

import {
  BRow, BCol, BCardText, BButton, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { getUserData } from '@/auth/utils'

import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from '@/utils/flatpickr-component/languages/pt'

import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'

import {
  onBeforeMount, ref, computed,
} from '@vue/composition-api'
import UsersDataCongratulation from './UsersDataCongratulation.vue'
import UserDataEarningsChart from './UserDataEarningsChart.vue'

import useDashboardUsersData from './useDashboardUsersData'

export default {
  components: {
    BRow,
    BCol,
    BCardText,
    BButton,
    BOverlay,
    vSelect,
    flatPickr,
    StatisticCardVertical,
    UserDataEarningsChart,
    UsersDataCongratulation,
    StatisticCardWithAreaChart,
  },
  directives: {
    Ripple,
  },
  data() {
    const validDates = date => date.getTime() <= new Date().getTime()

    return {
      filteredCampaing: { label: 'Todas as campanhas', value: '' },
      config: {
        mode: 'range',
        dateFormat: 'm-d-Y',
        altFormat: 'd-m-Y',
        altInput: true,
        locale: Portuguese,
        enable: [
          validDates,
        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      isAPICallInProgress: 'api/isAPICallInProgress',
    }),
  },
  setup() {
    const {
      campaignsFilterToken,
      periodDateFilter,
      campaignsList,
      usersRegisterData,
      sharingData,
      userSharingData,
      viewsData,
      uniqueAccessData,
      activeUsersData,
      registerPercentageData,
      activeUsersPercentageData,
      sharingPercentageData,
      fetchDashboardUsersData,
      fetchCampaigns,
    } = useDashboardUsersData()

    const rangeDate = ref(null)
    const dateDiff = computed(() => {
      const rangeSplit = periodDateFilter.value ? periodDateFilter.value.split(' ') : []
      const firstDate = new Date(rangeSplit[0])
      const endDate = new Date(rangeSplit[2])
      const diff = (endDate.getTime() - firstDate.getTime()) / (1000 * 3600 * 24) + 1

      return diff
    })

    const userData = getUserData()
    const congratulations = ref({
      name: userData.FantasyName,
      activeUsers: activeUsersData,
    })

    onBeforeMount(() => {
      fetchDashboardUsersData()
      fetchCampaigns()
    })

    const campaignsOptions = campaignsList

    function refetchData() {
      fetchDashboardUsersData()
    }

    return {
      rangeDate,
      dateDiff,
      campaignsFilterToken,
      campaignsList,
      campaignsOptions,
      periodDateFilter,
      congratulations,
      usersRegisterData,
      sharingData,
      userSharingData,
      viewsData,
      uniqueAccessData,
      activeUsersData,
      registerPercentageData,
      activeUsersPercentageData,
      sharingPercentageData,
      fetchDashboardUsersData,
      refetchData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/chart-apex.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
