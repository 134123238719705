<template>
  <b-card
    v-if="data"
    class="earnings-card"
  >
    <b-row>
      <b-col
        cols="6"
        class="d-flex flex-column justify-content-between"
      >
        <div>
          <b-card-title class="mb-1">
            {{ title }}
          </b-card-title>
          <div class="font-small-2">
            {{ legend }}
          </div>
        </div>
        <div>
          <feather-icon
            size="21"
            :color="earningsChart.chartOptions.colors[0]"
            :icon="icon"
          />
        </div>
      </b-col>
      <b-col cols="6">
        <!-- chart -->
        <vue-apex-charts
          type="radialBar"
          height="120"
          :options="earningsChart.chartOptions"
          :series="data"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BCardTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    VueApexCharts,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    icon: {
      type: String,
      default: () => '',
    },
    title: {
      type: String,
      default: '',
    },
    legend: {
      type: String,
      default: '',
    },
    colorOfGraph: {
      type: String,
      default: 'success',
    },
  },
  data() {
    return {
      earningsChart: {
        chartOptions: {
          chart: {
            type: 'radialBar',
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: true,
          },
          legend: { show: false },
          labels: [''],
          stroke: { width: 0 },
          // eslint-disable-next-line dot-notation
          colors: [$themeColors[this.colorOfGraph]],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            radialBar: {
              hollow: {
                margin: 15,
                size: '60%',
              },

              dataLabels: {
                showOn: 'always',
                name: {
                  offsetY: -150,
                  show: true,
                  color: '#888',
                  fontSize: '13px',
                },
                value: {
                  offsetY: -8,
                  color: '#111',
                  fontSize: '18px',
                  show: true,
                },
              },
            },
          },
        },
      },
    }
  },
}
</script>
