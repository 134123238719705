import useJwt from '@/auth/jwt/useJwt'
import { ref } from '@vue/composition-api'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useDashboardUsersData() {
  const toast = useToast()

  const today = new Date()
  const todayLocaleString = today.toLocaleString()
  const ninetyDaysAgo = new Date()
  ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - 90)
  const ninetyDaysAgoLocaleString = ninetyDaysAgo.toLocaleString()

  const usersRegisterData = ref(0)
  const sharingData = ref(0)
  const userSharingData = ref(0)
  const viewsData = ref(0)
  const uniqueAccessData = ref(0)
  const activeUsersData = ref(0)
  const registerPercentageData = ref(0)
  const activeUsersPercentageData = ref(0)
  const sharingPercentageData = ref(0)

  const periodDateFilter = ref(null)
  const campaignsList = ref([])
  const campaignsFilterToken = ref(null)

  const endDate = ref(
    `${todayLocaleString.slice(6, 10)}-${todayLocaleString.slice(3, 5)}-${todayLocaleString.slice(0, 2)}`,
  )

  const initDate = ref(
    `${ninetyDaysAgoLocaleString.slice(6, 10)}-${ninetyDaysAgoLocaleString.slice(3, 5)}-${ninetyDaysAgoLocaleString.slice(0, 2)}`,
  )

  const invertDateOrder = date => {
    const invertedDate = `${date.slice(6, 10)}-${date.slice(0, 2)}-${date.slice(
      3,
      5,
    )}`
    return invertedDate
  }

  const fetchCampaigns = () => {
    useJwt
      .listCampaingInDashboard()
      .then(response => {
        const campaignsLista = response.data

        const listaDeCampanhas = []
        // eslint-disable-next-line no-unused-vars
        campaignsLista.map(c => listaDeCampanhas.push({ label: c.campaignName, value: c.campaignToken }))
        listaDeCampanhas.unshift({ label: 'Todas as campanhas', value: null })
        campaignsList.value = listaDeCampanhas
        return response
      })
      .catch(error => error)
  }

  const fetchDashboardUsersData = () => {
    useJwt.dashboardUsersData({
      initDate: periodDateFilter.value === null || periodDateFilter.value === ''
        ? `${initDate.value}T00:00:00.0000001`
        : `${invertDateOrder(
          periodDateFilter.value.slice(0, 10),
        )}T00:00:00.0000001`,

      endDate: periodDateFilter.value === null || periodDateFilter.value === ''
        ? `${endDate.value}T23:59:59.9999999`
        : `${invertDateOrder(
          periodDateFilter.value.slice(15, 25),
        )}T23:59:59.9999999`,
      campaignTokens: campaignsFilterToken.value === null ? [] : [campaignsFilterToken.value],
    })
      .then(response => {
        const {
          usersRegister,
          sharing,
          userSharing,
          views,
          uniqueAccess,
          activeUsers,
          registerPercentage,
          activeUsersPercentage,
          sharingPercentage,
        } = response.data.result

        usersRegisterData.value = usersRegister
        sharingData.value = sharing
        userSharingData.value = userSharing
        viewsData.value = views
        uniqueAccessData.value = uniqueAccess
        activeUsersData.value = activeUsers
        registerPercentageData.value = registerPercentage
        activeUsersPercentageData.value = activeUsersPercentage
        sharingPercentageData.value = sharingPercentage

        return response
      })
      .catch(error => {
        usersRegisterData.value = 0
        sharingData.value = 0
        userSharingData.value = 0
        viewsData.value = 0
        uniqueAccessData.value = 0
        activeUsersData.value = 0
        registerPercentageData.value = 0
        activeUsersPercentageData.value = 0
        sharingPercentageData.value = 0

        toast({
          component: ToastificationContent,
          props: {
            title: 'Ocorreu um erro',
            text: error.response.data.errors[0],
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    campaignsList,
    periodDateFilter,
    campaignsFilterToken,
    usersRegisterData,
    sharingData,
    userSharingData,
    viewsData,
    uniqueAccessData,
    activeUsersData,
    registerPercentageData,
    activeUsersPercentageData,
    sharingPercentageData,
    fetchCampaigns,
    fetchDashboardUsersData,
  }
}
